import CTAButton from "../components/CTAButton";
import Footer from "../components/Footer";
import Header from "../components/Header";
import StepCard from "../components/StepCard";
import VideoSection from "../components/VideoSection";
import "../css/Home.scss";
import AccordionItem from "../components/AccordionItem";
import { useState, useRef } from "react";
import Modal from "../components/Modal";
import { faqData } from "../data/faqData";
// import FlodeskForm from "../components/FlodeskForm";
import useFocusTrap from "../hooks/useFocusTrap";

function HomePage() {
    const modalContainerRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [animationState, setAnimationState] = useState(true);

    useFocusTrap(modalContainerRef, showModal, {});

    function handleSetShowModal(val) {
        if (val) {
            setTimeout(() => {
                document
                    .querySelector(".modal-container .close-button")
                    .focus();
            }, 1);

            document.querySelector(".modal-container").style.display = "flex";
        } else {
            setTimeout(() => {
                document.querySelector(".modal-container").style.display =
                    "none";
            }, 500);
        }

        setShowModal(val);
    }

    function defaultCTAHandler() {
        handleSetShowModal(true);
    }

    function toggleAnimationHandler() {
        const video = document.querySelector("#home-hero-video");

        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }

        setAnimationState((prev) => {
            document
                .querySelectorAll(
                    "#home-vertical-text-slide .options-container span"
                )
                .forEach(
                    (el) =>
                        (el.style.animationPlayState = prev
                            ? "paused"
                            : "running")
                );
            return !prev;
        });
    }

    return (
        <div className="App">
            <Header CTAHandler={defaultCTAHandler} className={"header"} />
            <Modal
                showModal={showModal}
                setShowModal={handleSetShowModal}
                modalContainerRef={modalContainerRef}
            />
            <main>
                <VideoSection
                    videoId={"home-hero-video"}
                    video={"/home-hero-video.mp4"}
                    image={"/home-hero-first-frame.webp"}
                    className={"home-hero"}
                    color={"#04013f96"}
                    videoDescription={
                        "A silent video of a young woman sitting on a bed. She looks off to the side, places her head on her hands and sighs. She leans back and the video blurs."
                    }
                >
                    <div className="home-hero-content">
                        <div id="home-hero-top">
                            <strong
                                style={{
                                    color: "white",
                                    fontSize: 36,
                                    lineHeight: "42px",
                                    marginBlock: 10,
                                    maxWidth: 480,
                                }}
                            >
                                Ready to try the most innovative Mental Health
                                Care and training platform?
                            </strong>
                            <div
                                id="home-hero-controls"
                                className="hero-controls"
                            >
                                <button
                                    onClick={toggleAnimationHandler}
                                    aria-label={`${
                                        animationState ? "Pause" : "Resume"
                                    } text animation and background video`}
                                >
                                    <img
                                        src={`/${
                                            animationState ? "pause" : "play"
                                        }-icon.png`}
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                        <p style={{ color: "white", maxWidth: 480 }}>
                            We understand the challenges you may be facing. We
                            are here to provide you with support, connection,
                            and a way forward.
                        </p>
                        <CTAButton onClick={defaultCTAHandler}>
                            Start your demo
                        </CTAButton>
                    </div>
                </VideoSection>
                <section className="solution-section">
                    <div className="step-card-row">
                        <StepCard
                            icon={"step1_icon.png"}
                            heading={"Step 1"}
                            alt={"Survey"}
                            description={
                                "Register with your postsecondary school email and follow the steps to create an account."
                            }
                        />

                        <StepCard
                            icon={"step2_icon.png"}
                            heading={"Step 2"}
                            alt={"Get matched with a care provider"}
                            description={
                                "Choose a care provider from a list of recommended matches."
                            }
                        />

                        <StepCard
                            icon={"step3_icon.png"}
                            heading={"Step 3"}
                            alt={"Book session"}
                            description={
                                "Book your first session and start feeling better today."
                            }
                        />
                    </div>
                    <div className="solutions-container">
                        <div className="graphic">
                            <p
                                id="woman-on-couch-description"
                                className="background-image-description"
                            >
                                Woman sitting on a couch, using a laptop on her
                                lap.
                            </p>
                            <div
                                className="overlay-img-small"
                                style={{
                                    backgroundImage: "url(/woman_on_couch.jpg)",
                                }}
                                aria-labelledby="woman-on-couch-description"
                                role="img"
                            ></div>
                            <img
                                src="/phone_mockup.png"
                                className="overlay-img-floating"
                                draggable="false"
                                alt="Mobile app dashboard showing My Care Providers, Upcoming sessions and various messaging features"
                            />
                        </div>
                        <div className="content">
                            <h2>Solution features</h2>
                            <div class="solutions-grid">
                                <img
                                    src="/solutions/artificial-intelligence.png"
                                    alt="icon of a brain beside an icon of a lightning bolt"
                                />
                                <div>
                                    <strong>Artificial Intelligence</strong>
                                    <div>
                                        Decision support tools that helps
                                        clinicians make decisions faster &amp;
                                        more accurately.
                                    </div>
                                </div>
                                <img
                                    src="/solutions/advanced-supervision.png"
                                    alt="icon of a woman on a video call with 5 other people on a computer monitor"
                                />
                                <div>
                                    <strong>Advanced Supervision</strong>
                                    <div>
                                        Supervisors can monitor sessions, gather
                                        insights, and provide feedback all in
                                        real time using a virtual one-way
                                        mirror.
                                    </div>
                                </div>
                                <img
                                    src="/solutions/outcome-measures.png"
                                    alt="icon of a 3D circle graph and a 3D line graph showing sample data"
                                />
                                <div>
                                    <strong>Outcome Measures</strong>
                                    <div>
                                        Distribute, collect, and track mental
                                        health symptoms and outcomes over time.
                                    </div>
                                </div>
                                <img
                                    src="/solutions/practice-management.png"
                                    alt="icon of a man standing beside a large laptop with a sample checklist on a piece of paper laying on the laptop's screen"
                                />
                                <div>
                                    <strong>Practice Management</strong>
                                    <div>
                                        Trainee files, notes, transcripts and
                                        recordings are all available in a single
                                        location.
                                    </div>
                                </div>
                                <img
                                    src="/solutions/tele-health.png"
                                    alt="icon of a cloud with a lock going through it"
                                />
                                <div>
                                    <strong>Tele Health</strong>
                                    <div>
                                        HIPAA/PIPEDA Compliant, video, audio,
                                        calendaring, API first &amp; flexible
                                        integration.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faq-section">
                    <div className="bg-section">
                        <img src="/woman_on_grass.png" alt="" />
                        <div className="overlay-color"></div>
                    </div>
                    <div className="top-content">
                        <div>
                            <h3>We're ready when you are.</h3>
                            <p>
                                We understand the challenges you may be facing.
                                We are here to provide you with support,
                                connection, and a way forward.
                            </p>
                        </div>
                        <CTAButton onClick={defaultCTAHandler}>
                            Start your demo
                        </CTAButton>
                    </div>
                    <div className="accordion-container">
                        {faqData.map((d, index) => (
                            <AccordionItem
                                key={d.title}
                                {...d}
                                id={"accordion-item-" + index}
                            />
                        ))}
                    </div>
                </section>
                {/* <div
                className="fd-form"
                id="fd-form-637662ccd6bfc824ac69bc5f"
            ></div> */}
                {/* <FlodeskForm /> */}
            </main>
            <Footer />
        </div>
    );
}

export default HomePage;
